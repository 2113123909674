<!--
 * @Author: jiang
 * @Date: 2021-07-13 11:19:00
 * @Description:
-->
<template>
  <el-form
    class="b-form-info"
    ref="form"
    label-width="120px"
  >

    <el-form-item
      label="所在部门："
      prop="department_id"
    >
      <div>{{ row.department.name }}</div>
    </el-form-item>

    <el-form-item
      label="姓名："
      prop="user_id"
    >
      <div>{{ row.user.name }}</div>
    </el-form-item>

    <el-form-item
      label="时间："
      prop="schedule_date"
    >
      <div>{{ row.schedule_date }}</div>
    </el-form-item>

    <el-form-item
      label="内容："
      prop="content"
    >
      <div>{{ row.content }}</div>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  props: {
    row: Object,
  },
}
</script>
